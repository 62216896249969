import request from '@/utils/request'

export function getList(data) {
  return request({
    url: 'task/getList',
    method: 'post',
    data
  })
}
export function saveTask(data) {
  return request({
    url: 'task/saveTask',
    method: 'post',
    data
  })
}
export function deleteTask(data) {
  return request({
    url: 'task/deleteTask',
    method: 'post',
    data
  })
}

export function stopTask(data) {
  return request({
    url: 'task/stopTask',
    method: 'post',
    data
  })
}

export function startTask(data) {
  return request({
    url: 'task/startTask',
    method: 'post',
    data
  })
}

export function executeTask(data) {
    return request({
      url: 'task/executeTask',
      method: 'post',
      data
    })
  }


 
