<template>
  <div class="taskinfo-container">
    <section>
      <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
        <el-form :inline="true" :model="filters">
          <el-form-item>
            <el-button size="small" type="success" @click="Create">创建</el-button>
          </el-form-item>
        </el-form>
      </el-col>

      <!--列表-->
      <el-table size="mini" :data="tableData" highlight-current-row expand-on-click-node=false    :header-cell-style="{
        'font-weight': '400',
        height: '40px',
        padding: '0',
        background: '#4a8bc1',
        color: 'white',
      }" v-loading="listLoading" style="width: 100%;">
        <el-table-column label="操作" width="300" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="Update(scope.row)">修改</el-button>
            <el-button type="text" size="small" @click="Delete(scope.$index, scope.row)">删除</el-button>
            <el-button type="text" size="small" @click="btnStart(scope.$index, scope.row)">启动</el-button>
            <el-button type="text" size="small" @click="btnStop(scope.$index, scope.row)">停止</el-button>
            <el-button type="text" size="small" @click="btnManual(scope.$index, scope.row)">同步执行</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="名称" min-width="200">
        </el-table-column>
        <el-table-column prop="handler" label="执行函数" min-width="200">
        </el-table-column>
        <el-table-column prop="parameter" label="参数" min-width="100">
        </el-table-column>
        <el-table-column prop="enabled" label="是否可用" min-width="100" :formatter="boolFormat">
        </el-table-column>
        <el-table-column prop="scheduleType" label="运行频率" min-width="100">
        </el-table-column>
        <el-table-column prop="scheduleValue" label="运行频率值" min-width="100">
        </el-table-column>
        <el-table-column prop="runingState" label="运行状态" min-width="500">
        </el-table-column>
        <el-table-column prop="cluster" label="集群方式运行" min-width="100" :formatter="boolFormat">
        </el-table-column>
        <el-table-column prop="runingMode" label="运行模式" min-width="150">
        </el-table-column>
        <el-table-column prop="log" label="日志" min-width="150">
        </el-table-column>
        <el-table-column prop="remark" label="备注" min-width="150">
        </el-table-column>

      </el-table>

      <!--新增界面-->
      <div v-if="formVisible">
        <el-dialog v-dragDialog :title="textMap[dialogStatus]" :visible.sync="formVisible" width="450px" :close-on-click-modal="false">
          <el-form :model="addForm" ref="dataForm" label-width="110px" :rules="formRules">
            <el-form-item prop="Key" hidden>
              <el-input v-model="addForm.Key" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="名称:" prop="name">
              <el-col :span="19">
                <el-input v-model="addForm.name" auto-complete="off" placeholder="请输入名称"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="执行函数:" prop="handler">
              <el-col :span="19">
                <el-input v-model="addForm.handler" auto-complete="off" placeholder="请输入备注"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="是否启用:" prop="enabled">
              <el-col :span="19">
                <el-radio-group v-model="addForm.enabled" style="margin-left:20px">
                  <el-radio label="1" name="gender" checked>是</el-radio>
                  <el-radio label="0" name="gender">否</el-radio>
                </el-radio-group>
              </el-col>
            </el-form-item>
            <el-form-item label="运行频率:" prop="scheduleType">
              <el-col :span="19">
                <el-select v-model="addForm.scheduleType" placeholder="请选择运行频率" value-key="Key" style="width:100%">
                  <el-option v-for="item in ScheduleType" :key="item.Key" :label="item.Name" :value="item">
                  </el-option>
                </el-select>
              </el-col>
            </el-form-item>
            <el-form-item label="运行频率值:" prop="scheduleValue">
              <el-col :span="19">
                <el-input v-model="addForm.scheduleValue" auto-complete="off" placeholder="请输入备注"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="运行模式" prop="runingMode">
              <el-col :span="19">
                <el-select v-model="addForm.runingMode" placeholder="请选择运行模式" value-key="Key" style="width:100%">
                  <el-option v-for="item in RunningMode" :key="item.Key" :label="item.Name" :value="item">
                  </el-option>
                </el-select>
              </el-col>
            </el-form-item>
            <el-form-item label="日志级别" prop="log">
              <el-col :span="19">
                <el-select v-model="addForm.log" placeholder="请选择日志级别" value-key="Key" style="width:100%">
                  <el-option v-for="item in LoggingLevel" :key="item.Key" :label="item.Name" :value="item">
                  </el-option>
                </el-select>
              </el-col>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
              <el-col :span="19">
                <el-input v-model="addForm.remark" type="textarea" auto-complete="off" placeholder="请输入备注"></el-input>
              </el-col>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="formVisible = false">取消</el-button>
            <el-button v-if="dialogStatus=='create'" :loading='submitLoading' type="primary" @click="createSubmit">提交</el-button>
            <el-button v-else type="primary" :loading='submitLoading' @click="updateSubmit">提交</el-button>
          </div>
        </el-dialog>
      </div>
    </section>
  </div>
</template>

<script>
import { getList, saveTask, deleteTask, stopTask, startTask, executeTask } from "@/api/setting/task";
export default {
  data () {
    let _this = this;
    return {
      filters: {
        search: ""
      },

      ScheduleType: [
        { Key: 'OneTime', Name: 'OneTime', Value: 0 },
        { Key: 'Interval', Name: 'Interval', Value: 1 },
        { Key: 'Daily', Name: 'Daily', Value: 2 },
        { Key: 'Weekly', Name: 'Weekly', Value: 3 },
        { Key: 'Monthly', Name: 'Monthly', Value: 4 },
      ],
      RunningMode: [
        { Key: 'Singleton', Name: '单实例', Value: 0 },
        { Key: 'ByMachine', Name: '一服务器一实例', Value: 1 },
        { Key: 'Extent', Name: '可扩展', Value: 2 },
      ],
      LoggingLevel: [
        { Key: 'Fatalness', Name: 'Fatalness', Value: 10 },
        { Key: 'Error', Name: 'Error', Value: 30 },
        { Key: 'Warning', Name: 'Warning', Value: 50 },
        { Key: 'Info', Name: 'Info', Value: 70 },
        { Key: 'Verbose', Name: 'Verbose', Value: 100 },
        { Key: 'Off', Name: 'Off', Value: 0 },
      ],
      tableData: [],
      listLoading: false,
      submitLoading: false,
      total: 0,
      pageIndex: 1,
      pageSize: 10,
      pageSizesList: [10, 15, 20, 30, 50],

      dialogStatus: "",
      formVisible: false,
      textMap: {
        create: "创建",
        update: "修改"
      },
      addForm: {
        key: "",
        name: "",
        handler: "",
        parameter: "",
        enabled: "1",
        scheduleType: "",
        scheduleValue: "",
        cluster: "",
        runingMode: "",
        log: "",
        remark: "",
      },
      formRules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        handler: [{ required: true, message: "请输入执行函数", trigger: "blur" }],
        scheduleType: [{ required: true, message: "请选择运行频率", trigger: "change" }],
        enabled: [{ required: true, message: "请选择是否启用", trigger: "change" }],
        scheduleValue: [{ required: true, message: "请输入频率值", trigger: "change" }],
        runingMode: [{ required: true, message: "请选额运行模式", trigger: "change" }],
        log: [{ required: true, message: "请选择日志级别", trigger: "change" }]
      }
    };
  },
  methods: {
    queryListData () {
      let para = {
      };
      this.listLoading = true;
      getList(para).then(response => {
        this.listLoading = false;
        if (response) {
          this.tableData = response.data;
          this.total = response.total;
        }
      });
    },
    Create () {
      this.dialogStatus = "create";
      this.formVisible = true;
      this.addForm.key = "";
      this.addForm.name = "";
      this.addForm.handler = "";
      this.addForm.parameter = "";
      this.addForm.enabled = "1";
      this.addForm.scheduleType = "";
      this.addForm.scheduleValue = "";
      this.addForm.cluster = "";
      this.addForm.runingMode = "";
      this.addForm.log = "";
      this.addForm.remark = "";

      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },

    createSubmit: function () {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          let para = {
            Key: "",
            Name: this.addForm.name,
            Handler: this.addForm.handler,
            Parameter: this.addForm.parameter,
            IsEnabled: this.addForm.enabled,
            ScheduleType: this.addForm.scheduleType.Key,
            ScheduleValue: this.addForm.scheduleValue,
            IsRunOnCluster: this.addForm.cluster,
            RunningMode: this.addForm.runingMode.Key,
            LoggingLevel: this.addForm.log.Key,
            Description: this.addForm.remark
          };
          this.$confirm("确认提交吗？", "提示", {
            confirmButtonText: "确认",
            cancelButtonText: "取消"
          }).then(() => {
            this.submitLoading = true

            saveTask(para).then(response => {
              this.submitLoading = false;
              if (response) {
                this.formVisible = false;
                this.queryListData();
              }
            });
          });
        }
      });
    },

    Update (row) {
      this.addForm = JSON.parse(JSON.stringify(row));
      if (this.addForm.enabled == true) {
        this.addForm.enabled = "1"
      }
      else {
        this.addForm.enabled = "0"
      }
      this.dialogStatus = "update";
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },

    updateSubmit: function () {
      this.$confirm("确认提交吗？", "提示", {}).then(() => {
        let para = {
          Key: this.addForm.key,
          Name: this.addForm.name,
          Handler: this.addForm.handler,
          Parameter: this.addForm.parameter,
          IsEnabled: this.addForm.enabled == 1 ? true : false,
          ScheduleType: this.addForm.scheduleType.Key,
          ScheduleValue: this.addForm.scheduleValue,
          IsRunOnCluster: this.addForm.cluster,
          RunningMode: this.addForm.runingMode.Key,
          LoggingLevel: this.addForm.log.Key,
          Description: this.addForm.remark
        };
        this.submitLoading = true
        saveTask(para).then(response => {
          this.submitLoading = false;
          if (response) {
            this.formVisible = false;
            this.queryListData();
          }
        });
      });
    },
    //删除
    Delete: function (index, row) {
      this.$confirm("确认删除该记录吗?", "提示", {
        type: "warning"
      })
        .then(() => {

          let para = { key: row.Key };
          deleteTask(para).then(response => {

            if (response) {
              this.msg.successMsg("操作成功");
              this.queryListData();
            }
          });
        })
    },
    btnStart: function (index, row) {
      this.$confirm("确认启动该任务吗?", "提示", {
        type: "warning"
      })
        .then(() => {

          let para = { name: row.name };
          startTask(para).then(response => {

            if (response) {
              this.msg.successMsg("操作成功");
              this.queryListData();
            }
          });
        })
    },
    btnStop: function (index, row) {
      this.$confirm("确认停止该任务吗?", "提示", {
        type: "warning"
      })
        .then(() => {
          let para = { name: row.name };
          stopTask(para).then(response => {
            if (response) {
              this.msg.successMsg("操作成功");
              this.queryListData();
            }
          });
        })
    },
    btnManual: function (index, row) {
      this.$confirm("确认执行该任务吗?", "提示", {
        type: "warning"
      })
        .then(() => {

          let para = { name: row.name };
          executeTask(para).then(response => {
            if (response) {
              this.msg.successMsg("操作成功");
              this.queryListData();
            }
          });
        })
    },
  },
  mounted () {
    this.queryListData();
  }
};
</script>

<style lang='scss' scoped>
.taskinfo-container {
  padding: 10px;
}
/deep/.el-dialog {
  margin-top: 10px !important;
}
/deep/.el-input--small .el-input__inner {
  height: 36px;
  line-height: 36px;
}
</style>

